import React from "react";

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import theme from 'theme';

import { Subtheme } from '@starberryComponentsMui';
import Box from "@mui/material/Box"
import GlobalConversationalForm from "../components/MyAccount/conversationalforms"
import { StarberryRootThemeProvider } from "../StarberryComponentsMui"
import Layout from "../components/Layout"
import registerPropertyPreferences from '@myaccount/conversationalforms/forms/registerPropertyPreference';

const FormTestPage = () => {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StarberryRootThemeProvider theme={theme}>
                <Subtheme subtheme="form-test" applyStyling={true} applyProps={true}>{
                    ({className}) => (
                        <Layout>
                            <Box my={4} mx="auto" maxWidth={1024}>
                                <Grid item className={className}>
                                    <Typography variant="h1">Conversational forms testing</Typography>
                                    <Box mt={8} />
                                    <Paper elevation={0} variant="outlined">
                                        <Box px={2} py={4}>
                                            <GlobalConversationalForm
                                                form={registerPropertyPreferences}
                                                onSubmit={() => console.log('submit')}
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Box>
                        </Layout>
                    )
                }</Subtheme>
            </StarberryRootThemeProvider>
        </LocalizationProvider>
    );;
}

export default FormTestPage;
